.FormGridCol {
  flex: 1;
}

.CvcNumberInfo {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-decoration: underline;
  color: var(--primary-color);
}

.CheckOption {
  font-size: 12px;
}

.CustomOption {
  padding: 0px;
  margin-top: 2px;
  color: black;
}
.CustomOption:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.DateOption {
  padding: 5px 12px;
  margin-top: 2px;
  color: black;
  border-radius: 5px;
}

.DateOption:hover {
  color: var(--primary-color);
  cursor: pointer;
  background-color: #eeeeee;
}

.CustomSelect > .ant-select-selector {
  padding: 4.5px;
  border-radius: 3px;
  padding-right: 30px;
  height: auto;
}

.Menu{
  max-height: 200px;
  overflow-y: hidden;
}

.Scroll {
  overflow-y: auto;
}

