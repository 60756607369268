.OrderdetailsMainContainer {
  min-height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.OrderDetailsSection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.OrderDetailsLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}
.OrderDetailsWrapper {
  width: 100%;
  max-width: 600px;
  margin: 0 auto 40px;
}

.OrderDetailsHeader {
  margin-bottom: 50px;
}
.Title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000;
  margin-bottom: 20px;
}
.Title span {
  color: var(--primary-color);
}
.SubTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000;
}
.OrderDetails {
  display: flex;
  gap: 60px;
}
.OrderDetailsInfo {
}
.OrderDetailsThumb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.OrderDetailsThumb > img,
.OrderDetailsThumb > video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ProductTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}

.OrderDetailsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.OrderDetailsGridItem {
  width: calc(100% / 2 - 20px);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.16px;
  color: #000000;
}

@media only screen and (max-width: 1024px) {
  .OrderdetailsMainContainer {
    min-height: calc(100vh - 60px);
  }
}

@media only screen and (max-width: 450px) {
  .OrderDetails {
    width: 100%;
    display: block;
  }
  .OrderDetailsGrid {
    margin-top: 15px;
  }
  .ContainerMain {
    margin-top: 10px !important;
  }
}
