.Header {
  display: flex;
  align-items: center;
  background: #000000;
  min-height: 70px;
  padding: 5px 15px;
  /* position: sticky;
  top: 0;
  left: 0; */
}

.AbsoluteClass{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
}

.HeaderImg{
  cursor: pointer;
}

@media only screen and (max-width:768px){
  .HideOnMob{
    display: none;
  }
}

@media only screen and (max-width:575px){
  .AbsoluteClass{
    position: inherit;
  }
}
